import React from "react";
import "./ClickAndCollect.css";
import bgVideo from "../../Images/click-collect.mp4";
import book from "../../Images/ClickAndCollect/book.png";
import collect from "../../Images/ClickAndCollect/collection.png";
import deliver from "../../Images/ClickAndCollect/delivery.png";

const ClickAndCollect = () => {
  return (
    <>
      <div className="click-collect-container">
        <video className="background-video" autoPlay loop muted playsInline>
          <source src={bgVideo} type="video/mp4" />
          Your browser does not support the video tag.
        </video>
        <div className="click-collect-content">
          <div className="left">
            <div className="use-case-box">
              <p className="use-case-title">Instant Shopping</p>
            </div>
            <h1>
              Blitzo can do <span className="highlight">Same Day Delivery</span>{" "}
              for your <span className="highlight">Click and Collect</span>{" "}
              orders in <span className="highlight">Minutes</span>
            </h1>
            <h2>
              Need that Zara outfit for{" "}
              <span className="underline">tonight's event</span>? Place a{" "}
              <span className="underline">Click and Collect</span> order online,
              and <span className="underline">Blitzo</span> will{" "}
              <span className="underline">collect and deliver it</span> to you
              in <span className="underline">minutes</span>.
            </h2>
          </div>
        </div>
      </div>
      <HowItWorks />
    </>
  );
};

const HowItWorks = () => {
  return (
    <div className="how-it-works">
      <h2>How it Works - Instant Same Day Delivery</h2>
      <div className="steps">
        <div className="step">
          <div className="step-number">1</div>
          <img src={book} alt="Book Step" />
          <h2>Order Online</h2>
          <p>
            Shop online from your favorite store, like{" "}
            <span className="underline">Zara</span>, and select{" "}
            <span className="underline">Click and Collect</span> at checkout.
          </p>
        </div>
        <div className="step">
          <div className="step-number">2</div>
          <img src={collect} alt="Collect Step" />
          <h2>Collection by Blitzo</h2>
          <p>
            Our rider <span className="underline">collects</span> your order{" "}
            within <span className="underline">10 minutes</span> from the store
            and notifies you when it's picked up.
          </p>
        </div>
        <div className="step">
          <div className="step-number">3</div>
          <img src={deliver} alt="Deliver Step" />
          <h2>Delivered to You</h2>
          <p>
            We will deliver your <span className="underline">clothes</span> to
            your doorstep in <span className="underline">under 15 minutes</span>
            . Enjoy your new outfit!
          </p>
        </div>
      </div>
    </div>
  );
};

export default ClickAndCollect;
