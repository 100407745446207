import React from "react";
import "./ContentBlock.css";
import Rider from "../Images/Illustrations/rider.png";
import ThumbsUp from "../Images/Illustrations/thumbs-up.png";
import PlaceOrder from "../Images/Illustrations/place-order.png";

const ContentBlock = () => {
  return (
    <div className="triple-content-container">
      <h2>
        Simple, Swift, and Secure:{" "}
        <div className="main-heading">How to Send Parcels with Blitzo</div>
      </h2>
      <div className="content-block">
        <div className="text-content">
          <h3>1. Select Pickup & Dropoff</h3>
          <p>
            Open the <span className="underline">Blitzo</span> app, and enter
            your collection and delivery addresses. Blitzo will collect your
            parcel in under <span className="underline">10 minutes</span>, and
            deliver it to your desired location.
          </p>
        </div>
        <div className="image-content">
          <img src={PlaceOrder} alt="Place Order" />
        </div>
      </div>

      <div className="content-block">
        <div className="image-content">
          <img src={Rider} alt="Rider" />
        </div>
        <div className="text-content">
          <h3>2. Specify Parcel Details</h3>
          <p>
            Choose your parcel type and add any{" "}
            <span className="underline">Special Instructions</span>, such as{" "}
            <span className="underline">"Leave at Reception"</span>. Blitzo
            ensures each parcel is treated with care, tailored to your needs.
          </p>
        </div>
      </div>

      <div className="content-block">
        <div className="text-content">
          <h3>3. Pay & Track Delivery</h3>
          <p>
            You can pay using <span className="underline">Apple Pay</span> or
            your card. Voila! Your parcel is on its way. Track your delivery in
            real-time and receive{" "}
            <span className="underline">status updates</span> through
            notifications. Blitzo will ensure your package is delivered within{" "}
            <span className="underline">15 minutes</span>.
          </p>
        </div>
        <div className="image-content">
          <img src={ThumbsUp} alt="Thumbs Up" />
        </div>
      </div>
    </div>
  );
};

export default ContentBlock;
