import React from "react";
import "./footer.css";
import logoImage from "../Images/logob.png";
import { FaEnvelope } from "react-icons/fa";

const Footer = () => {
  return (
    <footer className="footer-container">
      <div className="footer-top">
        <div className="footer-logo">
          <img src={logoImage} alt="Blitzo Logo" className="logo" />
          <h2 className="logo-text">Blitzo</h2>
        </div>
        <div className="footer-content">
          <div className="footer-section contact">
            <h4>Contact Us</h4>
            <div className="contact-info">
              <div className="contact-info-item">
                <FaEnvelope />
                <span>support@blitzo.co.uk</span>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="footer-bottom">© 2023 Blitzo. All Rights Reserved</div>
    </footer>
  );
};

export default Footer;
