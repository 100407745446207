import React from "react";
import "./ContactUs.css";
import { FaPhone, FaFax, FaEnvelope } from "react-icons/fa"; // Make sure to install react-icons

function ContactUs() {
  return (
    <div className="contact-page">
      <div className="contact-form">
        <h2>Get in Touch</h2>
        <p className="intro-text">
          Your questions and comments are important to us. Fill out the form, or
          reach out using the information below.
        </p>
        <form>
          <input
            type="text"
            id="name"
            name="name"
            placeholder="Name *"
            required
          />
          <input type="email" id="email" name="email" placeholder="Email" />
          <input
            type="tel"
            id="phone"
            name="phone"
            placeholder="Phone number *"
            required
          />
          <select id="find-us" name="find-us">
            <option value="">How did you find us?</option>
            {/* Add more options here */}
          </select>
          <button type="submit" className="primary-button">
            SEND
          </button>
        </form>
      </div>
      <div className="contact-info">
        <h2>Contact Information</h2>
        <p>
          <FaPhone /> +44 1234 5678
        </p>
        <p>
          <FaFax /> +44 1234 5678
        </p>
        <p>
          <FaEnvelope /> contact@blitzo.com
        </p>
        {/* Add map iframe or static image */}
      </div>
    </div>
  );
}

export default ContactUs;
