import React, { useState } from "react";
import "./Stylists.css";
import { Link } from "react-router-dom";
import bgVideo from "../../Images/bg.mp4";
import book from "../../Images/Stylists/book.png";
import collect from "../../Images/Stylists/collection.png";
import deliver from "../../Images/Stylists/delivery.png";
import multidrop from "../../Images/Stylists/multidrop.png";

const Stylists = () => {
  return (
    <>
      <div className="stylists-container">
        <video className="background-video" autoPlay loop muted playsInline>
          <source src={bgVideo} type="video/mp4" />
          Your browser does not support the video tag.
        </video>
        <div className="stylist-content">
          <div className="left">
            <div className="use-case-box">
              <p className="use-case-title">Made for Stylists</p>
            </div>
            <h1>
              Instant Same Day{" "}
              <span className="highlight">Garment Deliveries</span> for London's{" "}
              <span className="highlight">Fashion Professionals</span>
            </h1>
            <h2>
              <span className="underline">Instant</span>,{" "}
              <span className="underline">reliable</span>, and{" "}
              <span className="underline">affordable</span> garment deliveries
              in London.
            </h2>
          </div>
        </div>
      </div>
      <HowItWorks />
    </>
  );
};

const HowItWorks = () => {
  const [isSingleDrop, setIsSingleDrop] = useState(true);
  return (
    <div className="how-it-works">
      <h2>How it Works - Instant Delivery</h2>
      <div className="stylists-toggle-duo-button">
        <button
          className={
            isSingleDrop
              ? "stylists-toggle-button active"
              : "stylists-toggle-button"
          }
          onClick={() => setIsSingleDrop(true)}
        >
          Single Drop
        </button>
        <button
          className={
            isSingleDrop
              ? "stylists-toggle-button"
              : "stylists-toggle-button active"
          }
          onClick={() => setIsSingleDrop(false)}
        >
          Multi Drop
        </button>
      </div>
      <div className="steps">
        <div className="step">
          <div className="step-number">1</div>
          <img src={isSingleDrop ? book : multidrop} alt="Step 1" />
          <h2>Place An Order</h2>
          <p>
            Use the <span className="underline">Blitzo</span> app to enter your
            pickup and dropoff locations. Enter any special instructions for
            your rider and set the parcel category as{" "}
            <span className="underline">
              {isSingleDrop ? "Clothes/Households" : "Garments"}
            </span>
            . Learn more about Multidrop{" "}
            <Link to="/multidrop" className="underline">
              here
            </Link>
            .
          </p>
        </div>
        <div className="step">
          <div className="step-number">2</div>
          <img src={collect} alt="Collect Step" />
          <h2>Garment Collection</h2>
          <p>
            We will notify all nearby{" "}
            <span className="underline">Blitzers</span>. Once assigned, your
            {isSingleDrop ? " Blitzer" : " Blitzers"} will arrive at your
            location <span className="underline">within 10 minutes</span> to
            collect your garments, and store it{" "}
            <span className="underline">securely</span>.
          </p>
        </div>
        <div className="step">
          <div className="step-number">3</div>
          <img src={deliver} alt="Deliver Step" />
          <h2>Garment Delivery</h2>
          <p>
            Your {isSingleDrop ? " Blitzer" : " Blitzers"} will deliver your
            garments <span className="underline">reliably</span> to the
            specified {isSingleDrop ? " location" : " locations"}. You can track
            your {isSingleDrop ? " Blitzer" : " Blitzers"} in{" "}
            <span className="underline">real-time</span> on the Blitzo app.
          </p>
        </div>
      </div>
    </div>
  );
};

const Blog = () => {
  return (
    <div className="blog-container">
      <h2 className="blog-title">
        Redefining Garment Logistics for Fashion Professionals
      </h2>
      <div className="card-container">
        <div className="blog-card">
          <h3 className="card-title">The Challenge</h3>
          <p className="card-content">
            Navigating the fast-paced demands of fashion shoots and events in
            London, stylists face constant pressure with time-sensitive garment
            sourcing, high courier costs, and logistical inefficiencies.
          </p>
        </div>
        <div className="blog-card">
          <h3 className="card-title">Blitzo's Innovative Solution</h3>
          <p className="card-content">
            Blitzo offers rapid, reliable, and cost-efficient logistics tailored
            for fashion events, with features like 10-minute pickups and
            deliveries within an hour, all tracked in real-time via our app.
          </p>
        </div>
        <div className="blog-card">
          <h3 className="card-title">Why Blitzo Stands Out</h3>
          <p className="card-content">
            Our eco-friendly e-bike fleet not only ensures timely delivery but
            also supports sustainable fashion initiatives, providing a secure
            and environmentally conscious option for garment transportation.
          </p>
        </div>
      </div>
      <button className="blog-cta">Learn More About Our Services</button>
    </div>
  );
};

export default Stylists;
