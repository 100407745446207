// App.jsx
import React from "react";
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import Home from "./pages/Home";
import ContactUs from "./pages/ContactUs";
import Footer from "./components/footer";
import Navbar from "./components/navbar";
import WorkWithUs from "./pages/WorkWithUs";
import "./App.css";
import FAQ from "./components/FAQ";
import WaitlistProvider from "./Contexts/WaitlistProvider";
import Terms from "./components/Terms";
import Stylists from "./components/UseCases/Stylists";
import Returns from "./components/UseCases/Returns";
import ClickAndCollect from "./components/UseCases/ClickAndCollect";
import Parcel from "./components/UseCases/Parcel";
import AboutUs from "./components/AboutUs";
import Tracker from "./pages/Tracker";
import Multidrop from "./components/Blogs/Multidrop";

function App() {
  return (
    <div className="App">
      <Router>
        <WaitlistProvider>
          <Navbar />
          <Routes>
            <Route path="/" element={<Home />} />
            <Route path="/contactUs" element={<ContactUs />} />
            <Route path="/workWithUs" element={<WorkWithUs />} />
            <Route path="/faq" element={<FAQ />} />
            <Route path="/terms" element={<Terms />} />
            <Route path="/stylists" element={<Stylists />} />
            <Route path="/clothing-returns" element={<Returns />} />
            <Route path="/click-collect" element={<ClickAndCollect />} />
            <Route path="/parcel-deliveries" element={<Parcel />} />
            <Route path="/about" element={<AboutUs />} />
            <Route path="/liveOrders/:orderId" element={<Tracker />} />
            <Route path="/multidrop" element={<Multidrop />} />
          </Routes>
          <Footer />
        </WaitlistProvider>
      </Router>
    </div>
  );
}

export default App;
