import React, { useContext } from "react";
import styled, { keyframes } from "styled-components";
import "./LandingPage.css";
import bikerImage from "../Images/biker.png";
import WaitlistContext from "../Contexts/WaitlistContext";
import appStore from "../Images/app-store-badge.png";
import googlePlay from "../Images/google-play-badge.png";
import keys from "../Images/keys.png";
import clothes from "../Images/clothes.png";
import returns from "../Images/returns.png";
import documents from "../Images/documents.png";
import pharma from "../Images/pharma.png";
import parcel from "../Images/parcel.png";
import errands from "../Images/errands.png";

const MellowBounce = keyframes`
  0%, 100% {
    transform: translateY(0);
  }
  50% {
    transform: translateY(-10px);
  }
`;

const BikerImage = styled.img`
  animation: 2s ${MellowBounce} ease-in-out infinite;
`;

const ScrollAnimation = keyframes`
  from {
    transform: translateX(0);
  }
  to {
    transform: translateX(-50%);
  }
`;

const MobileScrollAnimation = keyframes`
  from {
    transform: translateX(0);
  }
  to {
    transform: translateX(-400%);
  }
`;

const UseCaseContainer = styled.div`
  display: flex;
  overflow: hidden;
  width: 100%;
  justify-content: center;
  align-items: center;
  margin-vertical: 20px;
  padding: 20px 0;

  @media (max-width: 768px) {
    display: none;
  }
`;

const MobileUseCaseContainer = styled.div`
  display: flex;
  overflow: hidden;
  width: 90%;
  justify-content: center;
  align-items: center;
  margin-vertical: 20px;
  padding: 20px 0;
  @media (min-width: 768px) {
    display: none;
  }
  margin-horizontal: 20px;
`;

const UseCaseRow = styled.div`
  display: flex;
  animation: ${ScrollAnimation} 10s linear infinite;
  &:hover {
    animation-play-state: paused;
  }
`;

const MobileUseCaseRow = styled.div`
  display: flex;
  width: 200px;
  justify-content: center;
  animation: ${MobileScrollAnimation} 10s linear infinite;
  &:hover {
    animation-play-state: paused;
  }
`;

const UseCaseCard = styled.div`
  background: white;
  border-radius: 8px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  padding: 5px;
  margin: 0 10px;
  text-align: center;
  width: 100px;
  height: 100px;
  flex-shrink: 0;
  transition: transform 0.3s;
  &:hover {
    transform: translateY(-5px);
  }

  @media (max-width: 768px) {
    padding: 0px;
  }
  @media (max-width: 400px) {
    height: 80px;
    width: 100px;
    padding: 0px;
  }
`;

const UseCaseImage = styled.img`
  max-height: 50px;
  @media (max-width: 400px) {
    max-height: 30px;
  }
`;

const UseCaseTitle = styled.p`
  font-size: 0.9em;
  font-family: "Poppins", sans-serif;
  color: black;
  font-weight: bold;
`;

const LandingPage = () => {
  const { setModalOpen } = useContext(WaitlistContext);
  return (
    <div className="landing-container">
      <div className="content">
        <h1>
          Blitzo - <span className="highlight">15 Minute</span> Same Day Courier
          for <span className="highlight">London</span>
        </h1>
        <h2 className="enhanced-text">
          Send and Receive <span className="funky-underline">Parcels</span>{" "}
          across <span className="funky-underline">London</span> in just{" "}
          <span className="funky-underline">15 Minutes</span>
        </h2>
        <MobileUseCaseContainer>
          <MobileUseCaseRow>
            <UseCase image={keys} title="Keys" />
            <UseCase image={clothes} title="Clothes" />
            <UseCase image={returns} title="Returns" />
            <UseCase image={documents} title="Documents" />
            <UseCase image={pharma} title="Medicine" />
            <UseCase image={parcel} title="Parcel" />
            <UseCase image={errands} title="Errands" />
            <UseCase image={keys} title="Keys" />
            <UseCase image={clothes} title="Clothes" />
            <UseCase image={returns} title="Returns" />
            <UseCase image={documents} title="Documents" />
            <UseCase image={pharma} title="Medicine" />
            <UseCase image={parcel} title="Parcel" />
            <UseCase image={errands} title="Errands" />
          </MobileUseCaseRow>
        </MobileUseCaseContainer>

        <div className="badge-container">
          <div className="appStore-badge">
            <a
              href="https://apps.apple.com/gb/app/blitzo/id6502848319"
              target="_blank"
              rel="noopener noreferrer"
            >
              <img src={appStore} alt="App Store Badge" />
            </a>
          </div>
          <div className="googlePlayStore-badge">
            <a
              href="https://play.google.com/store/apps/details?id=com.blitzo&pcampaignid=web_share"
              target="_blank"
              rel="noopener noreferrer"
            >
              <img src={googlePlay} alt="Google Play Badge" />
            </a>
          </div>
        </div>
      </div>
      <div className="right-content">
        <div className="biker">
          <BikerImage src={bikerImage} alt="Biker illustration" />
        </div>
        <UseCaseContainer>
          <UseCaseRow>
            <UseCase image={keys} title="Keys" />
            <UseCase image={clothes} title="Clothes" />
            <UseCase image={returns} title="Returns" />
            <UseCase image={documents} title="Documents" />
            <UseCase image={pharma} title="Medicine" />
            <UseCase image={parcel} title="Parcel" />
            <UseCase image={errands} title="Errands" />
            <UseCase image={keys} title="Keys" />
            <UseCase image={clothes} title="Clothes" />
            <UseCase image={returns} title="Returns" />
            <UseCase image={documents} title="Documents" />
            <UseCase image={pharma} title="Medicine" />
            <UseCase image={parcel} title="Parcel" />
            <UseCase image={errands} title="Errands" />
          </UseCaseRow>
        </UseCaseContainer>
      </div>
    </div>
  );
};

export default LandingPage;

const UseCase = ({ image, title }) => {
  return (
    <UseCaseCard>
      <UseCaseImage src={image} alt={title} />
      <UseCaseTitle>{title}</UseCaseTitle>
    </UseCaseCard>
  );
};
