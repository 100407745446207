import React, { useContext, useState, useEffect, useRef } from "react";
import logoImage from "../Images/logo.png";
import "./navbar.css";
import { useNavigate } from "react-router-dom";
import WaitlistContext from "../Contexts/WaitlistContext";

function Navbar() {
  const navigate = useNavigate();
  const { setModalOpen } = useContext(WaitlistContext);
  const [isMobileMenuOpen, setIsMobileMenuOpen] = useState(false);
  const [activeSubmenu, setActiveSubmenu] = useState(null); // State for active submenu
  const navbarRef = useRef(null); // Reference to the navbar
  const [isCustomer, setIsCustomer] = useState(true);

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (navbarRef.current && !navbarRef.current.contains(event.target)) {
        setIsMobileMenuOpen(false);
        setActiveSubmenu(null); // Close submenus when clicking outside
      }
    };

    if (isMobileMenuOpen) {
      document.addEventListener("mousedown", handleClickOutside);
    }

    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [isMobileMenuOpen]);

  const toggleSubmenu = (menu) => {
    setActiveSubmenu(activeSubmenu === menu ? null : menu);
  };

  return (
    <nav className="navbar" ref={navbarRef}>
      <a href="/" className="logo">
        <img src={logoImage} alt="Blitzo Logo" />
        <h2>Blitzo</h2>
      </a>
      <div className="navbar-links">
        <div className="dropdown">
          <button className="navbar-link">Company</button>
          <div className="dropdown-content">
            <a href="/about">About Us</a>
            <a href="/terms">Terms</a>
            <a href="https://www.privacypolicies.com/live/0c0b870e-c126-4cbe-b8ab-9a25b9108610">
              Policy
            </a>
          </div>
        </div>
        <div className="dropdown">
          <button className="navbar-link">Use Cases</button>
          <div className="dropdown-content">
            <a href="/stylists">Stylists</a>
            <a href="/clothing-returns">Clothing Returns</a>
            <a href="/click-collect">Click & Collect</a>
            <a href="/parcel-deliveries">Parcel Deliveries</a>
          </div>
        </div>
        <div className="dropdown">
          <button className="navbar-link">Blogs</button>
          <div className="dropdown-content">
            <a href="/multidrop">Multidrop</a>
          </div>
        </div>
        <button className="navbar-link" onClick={() => navigate("/faq")}>
          FAQs
        </button>
      </div>
      <div className="toggle-duo-button">
        <button
          className={isCustomer ? "toggle-button active" : "toggle-button"}
          onClick={() => {
            setIsCustomer(true);
            navigate("/");
          }}
        >
          For Customers
        </button>
        <button
          className={isCustomer ? "toggle-button" : "toggle-button active"}
          onClick={() => {
            setIsCustomer(false);
            navigate("/workWithUs");
          }}
        >
          For Riders
        </button>
      </div>
      <div
        className="mobile-menu-icon"
        onClick={() => setIsMobileMenuOpen(!isMobileMenuOpen)}
      >
        {isMobileMenuOpen ? <span>&times;</span> : <span>&#9776;</span>}
      </div>
      {isMobileMenuOpen && (
        <div className="mobile-nav">
          <button
            className="close-btn"
            onClick={() => setIsMobileMenuOpen(false)}
          >
            &times;
          </button>
          <div className="mobile-toggle-duo">
            <button
              className={isCustomer ? "mobile-toggle active" : "mobile-toggle"}
              onClick={() => {
                setIsCustomer(true);
                navigate("/");
                setIsMobileMenuOpen(false);
              }}
            >
              For Customers
            </button>
            <button
              className={isCustomer ? "mobile-toggle" : "mobile-toggle active"}
              onClick={() => {
                setIsCustomer(false);
                navigate("/workWithUs");
                setIsMobileMenuOpen(false);
              }}
            >
              For Riders
            </button>
          </div>
          <div className="mobile-link">
            <button
              className="navbar-link"
              onClick={() => toggleSubmenu("company")}
            >
              Company
            </button>
            {activeSubmenu === "company" && (
              <div className="mobile-submenu">
                <a href="/about">About Us</a>
                <a href="/terms">Terms</a>
                <a href="https://www.privacypolicies.com/live/0c0b870e-c126-4cbe-b8ab-9a25b9108610">
                  Policy
                </a>
              </div>
            )}
          </div>
          <div className="mobile-link">
            <button
              className="navbar-link"
              onClick={() => toggleSubmenu("use-cases")}
            >
              Use Cases
            </button>
            {activeSubmenu === "use-cases" && (
              <div className="mobile-submenu">
                <a href="/stylists">Stylists</a>
                <a href="/clothing-returns">Clothing Returns</a>
                <a href="/click-collect">Click & Collect</a>
                <a href="/parcel-deliveries">Parcel Deliveries</a>
              </div>
            )}
          </div>
          <div className="mobile-link">
            <button
              className="navbar-link"
              onClick={() => toggleSubmenu("blogs")}
            >
              Blogs
            </button>
            {activeSubmenu === "blogs" && (
              <div className="mobile-submenu">
                <a href="/multidrop">Multidrop</a>
              </div>
            )}
          </div>
          <div className="mobile-link">
            <button className="navbar-link" onClick={() => navigate("/faq")}>
              FAQs
            </button>
          </div>
        </div>
      )}
    </nav>
  );
}

export default Navbar;
