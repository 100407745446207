import React from "react";
import "./Returns.css";
import bgVideo from "../../Images/returns.mp4";
import selectCategory from "../../Images/Returns/select-category.png";
import uploadQR from "../../Images/Returns/upload-qr.png";
import collect from "../../Images/Returns/collect.png";
import deliver from "../../Images/Returns/deliver.png";
import appStore from "../../Images/app-store-badge.png";
import googlePlay from "../../Images/google-play-badge.png";

const Returns = () => {
  return (
    <>
      <div className="returns-container">
        <video className="background-video" autoPlay loop muted playsInline>
          <source src={bgVideo} type="video/mp4" />
          Your browser does not support the video tag.
        </video>
        <div className="returns-content">
          <div className="left">
            <div className="use-case-box">
              <p className="use-case-title">Easy Returns</p>
            </div>
            <h1>
              Drowning in <span className="highlight">Shein</span> or{" "}
              <span className="highlight">ASOS Returns?</span>
              <br />
              Blitzo Handles Your Returns in{" "}
              <span className="highlight">Minutes</span>
            </h1>
            <h2>
              Forget about <span className="underline">printing labels</span>,{" "}
              <span className="underline">post office queues</span>, and{" "}
              <span className="underline">carrying loads of boxes</span>. Just{" "}
              <span className="underline">Blitzo</span> it and we'll post your
              returns in minutes.
            </h2>
          </div>
        </div>
      </div>

      <HowItWorks />
    </>
  );
};

const HowItWorks = () => {
  return (
    <div className="how-it-works">
      <h2>How it Works - Simplified Returns</h2>
      <div className="steps">
        <div className="step">
          <div className="step-number">1</div>
          <img src={selectCategory} alt="Select Category Step" />
          <h2>Place a Returns Orders</h2>
          <p>
            Open the <span className="underline">Blitzo</span> app, place a new
            order, and choose the{" "}
            <span className="underline">"Returns/Exchanges"</span> category.
            Enter your pickup location, and select the nearest{" "}
            <span className="underline">Post Office / InPost Locker</span> for
            the dropoff.
          </p>
        </div>
        <div className="step">
          <div className="step-number">2</div>
          <img src={uploadQR} alt="Upload QR Step" />
          <h2>Upload Return Label/QR </h2>
          <p>
            Upload your <span className="underline">QR code</span> or return
            label image. If you need a{" "}
            <span className="underline">printed label</span> for your returns,
            select 'Yes' for the printed label question. Blitzo will handle the{" "}
            <span className="underline">printing</span>,{" "}
            <span className="underline">sticking</span>, and{" "}
            <span className="underline">posting</span> for you!
          </p>
        </div>
        <div className="step">
          <div className="step-number">3</div>
          <img src={collect} alt="Collect Step" />
          <h2>Quick Collection</h2>
          <p>
            Your Blitzo rider will arrive{" "}
            <span className="underline">within 10 minutes</span> to collect your
            returns. No more waiting or carrying heavy boxes to the{" "}
            <span className="underline">post office</span>.
          </p>
        </div>
        <div className="step">
          <div className="step-number">4</div>
          <img src={deliver} alt="Deliver Step" />
          <h2>Swift Delivery</h2>
          <p>
            Your <span className="underline">Blitzo rider</span> will post your
            returns at the selected{" "}
            <span className="underline">Post Office / InPost Locker</span>{" "}
            within <span className="underline">10 minutes</span>. Track your
            rider in <span className="underline">real-time</span> on the Blitzo
            app. Enjoy your hassle-free returns!
          </p>
        </div>
      </div>
    </div>
  );
};

export default Returns;
