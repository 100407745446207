import React, { useContext, useEffect } from "react";
import JoinBlitzo from "../components/JoinBlitzo";
import "./WorkWithUs.css";
import ComingSoonRider from "../components/ComingSoonRider";
import WaitlistContext from "../Contexts/WaitlistContext";

const WorkWithUs = () => {
  const { setIsRiderModal } = useContext(WaitlistContext);
  useEffect(() => {
    setIsRiderModal(true);
  }, []);
  return (
    <div className="work-with-us-container">
      <JoinBlitzo />
      {/* <ComingSoonRider /> */}
    </div>
  );
};

export default WorkWithUs;
