import React from "react";
import "./AboutUs.css";
import { FaBuilding, FaShippingFast, FaRegHandshake } from "react-icons/fa";

const AboutUs = () => {
  return (
    <div className="about-us-container">
      <h1>About Blitzo</h1>
      <div className="about-us-cards-container">
        <div
          className="about-us-card"
          onMouseEnter={(e) => e.currentTarget.classList.add("hover")}
          onMouseLeave={(e) => e.currentTarget.classList.remove("hover")}
        >
          <FaBuilding className="about-us-icon" />
          <h2>Our Mission</h2>
          <p>
            Blitzo aims to redefine <strong>same day parcel delivery</strong>{" "}
            with our ultra-fast 15-minute courier service across London. We are
            committed to enhancing logistic efficiencies through rapid,
            reliable, and eco-friendly solutions. By integrating advanced
            technologies and optimizing delivery networks, we strive to meet the
            dynamic needs of urban living while minimizing environmental impact.
          </p>
          <p>
            We address the common challenges faced in urban logistics, such as
            unpredictable traffic, inefficient routing, and the high carbon
            footprint of traditional delivery methods. Our mission extends
            beyond just delivery; it's about creating a seamless experience that
            transforms how Londoners perceive and utilize courier services.
          </p>
        </div>
        <div
          className="about-us-card"
          onMouseEnter={(e) => e.currentTarget.classList.add("hover")}
          onMouseLeave={(e) => e.currentTarget.classList.remove("hover")}
        >
          <FaShippingFast className="about-us-icon" />
          <h2>What We Offer</h2>
          <p>
            As the leading <strong>same day courier in London</strong>, Blitzo
            specializes in lightning-fast deliveries tailored for modern needs.
            Our service portfolio includes urgent document dispatches, quick
            fashion returns for brands like <strong>ASOS</strong> and{" "}
            <strong>Shein</strong>, and comprehensive solutions for personal and
            business parcels.
          </p>
          <p>
            We pride ourselves on our ability to offer competitive pricing
            without compromising on speed or reliability. Our technology-driven
            approach ensures that every package is delivered on time, with
            real-time tracking and updates available at your fingertips. Our
            commitment to sustainability means we utilize a fleet of e-bikes and
            other low-emission vehicles, aligning with our eco-friendly goals.
          </p>
        </div>
        <div
          className="about-us-card"
          onMouseEnter={(e) => e.currentTarget.classList.add("hover")}
          onMouseLeave={(e) => e.currentTarget.classList.remove("hover")}
        >
          <FaRegHandshake className="about-us-icon" />
          <h2>Our Vision</h2>
          <p>
            Blitzo envisions a future where urban logistics are not just about
            speed, but also about intelligence and sustainability. We are at the
            forefront of transforming urban delivery systems with our advanced
            technology and a robust fleet of e-bikes. Our vision includes
            expanding our delivery capabilities through partnerships with London
            Underground and integrating rail networks to ensure coverage across
            the entire city.
          </p>
          <p>
            Our goal is to make Blitzo synonymous with reliability and
            innovation in the <strong>same day package delivery</strong> market.
            We are committed to continuous improvement, leveraging data-driven
            insights to optimize routes and reduce delivery times further. By
            doing so, we aim to set new standards in the courier industry and
            lead the charge toward a more efficient and environmentally
            conscious future.
          </p>
        </div>
      </div>
      <div className="founding-team">
        <h2>Meet Our Founding Team</h2>
        <p>[Founding team information and pictures will be added here]</p>
      </div>
    </div>
  );
};

export default AboutUs;
