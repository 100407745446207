import React, { useState, useEffect } from "react";
import "./Waitlist.css";
import { addToWaitlist } from "../Firebase/waitlist";

function UserWaitlist({ onFormSuccess }) {
  const [email, setEmail] = useState("");
  const [fullName, setFullName] = useState("");
  const [fullNameError, setFullNameError] = useState("");
  const [emailError, setEmailError] = useState("");
  const [successMessage, setSuccessMessage] = useState("");
  const [showSuccessModal, setShowSuccessModal] = useState(false);
  const [isStoring, setIsStoring] = useState(false);

  useEffect(() => {
    if (!localStorage.getItem("userDataArray")) {
      localStorage.setItem("userDataArray", JSON.stringify([]));
    }
  }, []);

  const handleSubmit = async (event) => {
    event.preventDefault();
    setIsStoring(true);
    setFullNameError("");
    setEmailError("");
    setSuccessMessage("");

    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    let isValid = true;

    if (!fullName.trim()) {
      setFullNameError("Please enter your full name.");
      isValid = false;
    }
    if (!emailRegex.test(email)) {
      setEmailError("Please enter a valid email address.");
      isValid = false;
    }

    if (isValid) {
      try {
        await addToWaitlist(fullName, email);
        setIsStoring(false);
        setSuccessMessage("Thank you for joining the waitlist!");
        setShowSuccessModal(true); // Show the success modal
        setTimeout(() => {
          setShowSuccessModal(false); // Hide the modal after a few seconds
          onFormSuccess(); // Call the success handler
        }, 5000);
      } catch (error) {
        setIsStoring(false);
        console.error("Error adding document: ", error);
        setSuccessMessage(
          `Failed to submit: ${error.message}. Please try again.`
        );
      }
    } else {
      setIsStoring(false);
    }
  };

  const successP =
    "Keep an eye on your email for exciting updates about our launch.";

  if (showSuccessModal) {
    return (
      <div className="success-modal">
        <div className="success-content">
          <span className="success-icon">✓</span>
          <h2>Thank You for Joining Our Waitlist!</h2>
          <p>{successP}</p>
        </div>
      </div>
    );
  } else {
    return (
      <div className="container">
        <section className="waitlistSection">
          <form className="waitlistForm" onSubmit={handleSubmit}>
            <h2>
              Join Blitzo's Customer Waitlist: Experience Instant Courier
              Delivery
            </h2>
            <input
              type="text"
              id="name"
              name="name"
              placeholder="Full Name"
              value={fullName}
              onChange={(e) => setFullName(e.target.value)}
              className="formInput"
            />
            {fullNameError && <p className="errorMessage">{fullNameError}</p>}
            <input
              type="email"
              id="email"
              name="email"
              placeholder="Enter your email here"
              value={email}
              onChange={(e) => setEmail(e.target.value)}
              className="formInput"
            />
            {emailError && <p className="errorMessage">{emailError}</p>}
            <button
              type="submit"
              className={`primary-button ${isStoring ? "loading" : ""}`}
            >
              {isStoring ? "Submitting..." : "Sign Me Up!"}
            </button>
            {successMessage && (
              <p className="successMessage">{successMessage}</p>
            )}
          </form>
        </section>
      </div>
    );
  }
}

export default UserWaitlist;
