import React, { useContext } from "react";
import DeliveryMan from "../Images/delivery-man.png";
import "./JoinBlitzo.css";
import WaitlistContext from "../Contexts/WaitlistContext";
import google from "../Images/google-play-badge.png";

const JoinBlitzo = () => {
  const { setModalOpen } = useContext(WaitlistContext);
  return (
    <div className="join-blitzo-container">
      <div className="parcel-delivery-image">
        <img src={DeliveryMan} alt="Parcel Delivery" />
      </div>
      <div className="join-blitzo-content">
        <h2>Join our Rider Network.</h2>
        <p>
          Embrace the opportunity to earn on your terms. Select jobs that suit
          your schedule and get paid well for your dedication.
        </p>
        <div className="google-play-badge">
          <a
            href="https://play.google.com/store/apps/details?id=com.blitzorider"
            target="_blank"
            rel="noopener noreferrer"
          >
            <img src={google} alt="Google Play Badge" />
          </a>
        </div>

        <div className="parcel-delivery-image-mobile">
          <img src={DeliveryMan} alt="Parcel Delivery" />
        </div>
        <div>
          <h3 className="benefits-title">Why Join Blitzo?</h3>
          <ul className="benefits-list">
            <li>
              <span className="benefit-title">Top Industry Pay:</span>{" "}
              Competitive rates that match your efforts.
            </li>
            <li>
              <span className="benefit-title">Total Flexibility:</span> Choose
              when you work and which deliveries to accept.
            </li>
            <li>
              <span className="benefit-title">Preference-Based Selection:</span>{" "}
              Take on deliveries that align with your preferences and location.
            </li>
            <li>
              <span className="benefit-title">Instant Withdrawals:</span>{" "}
              Request and receive payments swiftly.
            </li>
          </ul>
        </div>
      </div>
    </div>
  );
};

export default JoinBlitzo;
