import React, { useState } from "react";
import { Document, Page, pdfjs } from "react-pdf";
import "react-pdf/dist/esm/Page/AnnotationLayer.css";
import userTermsPDF from "../terms/userTerms.pdf";
import "./Terms.css";
import riderTermsPDF from "../terms/riderTerms.pdf";
import { useLocation, useNavigate } from "react-router-dom";

// You need to specify a workerSrc
pdfjs.GlobalWorkerOptions.workerSrc = `//unpkg.com/pdfjs-dist@${pdfjs.version}/build/pdf.worker.min.js`;

export default function Terms() {
  const [numPages, setNumPages] = useState(null);
  const location = useLocation();
  const navigate = useNavigate();
  const searchParams = new URLSearchParams(location.search);
  const isRider = searchParams.get("type") === "rider";

  function onDocumentLoadSuccess({ numPages }) {
    setNumPages(numPages);
  }

  return (
    <div className="terms-container">
      <div className="tabs">
        <button
          className={`tab ${!isRider ? "active" : ""}`}
          onClick={() => navigate("/terms")}
        >
          User Terms and Conditions
        </button>
        <button
          className={`tab ${isRider ? "active" : ""}`}
          onClick={() => navigate("/terms?type=rider")}
        >
          Rider Terms and Conditions
        </button>
      </div>
      <div className="pdf-viewer">
        <Document
          file={isRider ? riderTermsPDF : userTermsPDF}
          onLoadSuccess={onDocumentLoadSuccess}
        >
          {Array.from(new Array(numPages), (el, index) => (
            <div className="page">
              {" "}
              {/* Wraps each page in a div with class "page" */}
              <Page
                key={`page_${index + 1}`}
                renderMode="canvas"
                pageNumber={index + 1}
                renderTextLayer={false}
                height={800}
              />
            </div>
          ))}
        </Document>
      </div>
    </div>
  );
}

const ToggleButton = ({ isChecked, onClick }) => {
  return (
    <button onClick={onClick}>
      {isChecked ? "View User Terms" : "View Rider Terms"}
    </button>
  );
};
