import { db, functions } from "./firebase-config";
import {
  collection,
  doc,
  addDoc,
  onSnapshot,
  getDoc,
} from "firebase/firestore";
import { httpsCallable } from "firebase/functions";

const ACTIVE_ORDER_COLLECTION = "activeOrders";

const getActiveOrder = async (orderId) => {
  try {
    const docRef = doc(db, ACTIVE_ORDER_COLLECTION, orderId);
    const docSnap = await getDoc(docRef);
    return docSnap.data();
  } catch (e) {
    console.error("Error getting document: ", e);
  }
};

const subscribeToActiveOrder = (orderId, callback) => {
  const docRef = doc(db, ACTIVE_ORDER_COLLECTION, orderId);
  const unsubscribe = onSnapshot(docRef, (docSnap) => {
    callback(docSnap.data());
  });
  return unsubscribe;
};

const fetchDistanceInMilesAndDurationInMinutes = async (start, end) => {
  try {
    const fetchDistanceFunction = httpsCallable(
      functions,
      "fetchDistanceInMilesAndDurationInMinutes"
    );
    const response = await fetchDistanceFunction({ start, end });
    return response.data;
  } catch (error) {
    console.error(error);
  }
  return { distance: 0, duration: 0 };
};

const getPolyline = async (start, end) => {
  try {
    const getPolyLineFunction = httpsCallable(functions, "getPolyLine");
    const response = await getPolyLineFunction({ start, end });
    return response.data;
  } catch (error) {
    console.error(error);
  }
};

export {
  getActiveOrder,
  subscribeToActiveOrder,
  fetchDistanceInMilesAndDurationInMinutes,
  getPolyline,
};
