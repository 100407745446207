import React from "react";
import "./WhyBlitzo.css";

function WhyBlitzo() {
  const goToStore = () => {
    window.open("https://qrco.de/bfB4eg", "_blank");
  };
  return (
    <div className="why-blitzo-container">
      <h2>
        So, how does safe and affordable{" "}
        <span className="highlight">Same Day Parcel Delivery</span> within{" "}
        <span className="highlight">minutes</span> sound?
      </h2>
      <div className="service-offerings">
        <button className="get-in-touch-btn" onClick={goToStore}>
          Download Blitzo Now
        </button>
      </div>
    </div>
  );
}

export default WhyBlitzo;
