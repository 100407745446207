import {createContext} from 'react';

const WaitlistContext = createContext({
  setModalOpen: value => {},
  modalOpen: false,
  isRiderModal: false,
  setIsRiderModal: value => {},
});

export default WaitlistContext;
