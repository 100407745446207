import {db} from './firebase-config';
import {collection, addDoc} from 'firebase/firestore';

export const addToWaitlist = async (fullName, email) => {
  try {
    const docRef = await addDoc(collection(db, 'customerWaitlist'), {
      fullName,
      email,
    });
    console.log('Document written with ID: ', docRef.id);
  } catch (e) {
    console.error('Error adding document: ', e);
  }
};

export const addToRiderWaitlist = async (fullName, phoneNumber) => {
  try {
    const docRef = await addDoc(collection(db, 'riderWaitlist'), {
      fullName,
      phoneNumber,
    });
    console.log('Document written with ID: ', docRef.id);
  } catch (e) {
    console.error('Error adding document: ', e);
  }
};
