import React from "react";
import "./Categories.css";

const Categories = () => {
  const categories = [
    {
      title: "Fashion Stylists",
      description:
        "Instant garment deliveries tailored for stylists and fashion events, ensuring timely, reliable, and affordable delivery.",
      link: "/stylists",
      gradient: "linear-gradient(135deg, #e58c4c, #f8d5a5)",
    },
    {
      title: "Clothing Returns",
      description:
        "Easy clothing returns process with Blitzo. No more printing labels or waiting in line at the post office for your Shein or Asos Returns.",
      link: "/clothing-returns",
      gradient: "linear-gradient(135deg, #8c4ce5, #d5a5f8)",
    },
    {
      title: "Click and Collect",
      description:
        "Shop online and let Blitzo collect and deliver your orders to you in minutes.",
      link: "/click-collect",
      gradient: "linear-gradient(135deg, #4ce58c, #a5f8d5)",
    },
    {
      title: "Students and Professionals",
      description:
        "Quick and reliable same day courier for forgotten items and urgent parcels.",
      link: "/parcel",
      gradient: "linear-gradient(135deg, #4c8ce5, #a5c8f8)",
    },
  ];

  return (
    <div className="categories-container">
      <h2>Same Day Delivery for Every Use Case in Minutes</h2>
      <div className="card-grid">
        {categories.map((category, index) => (
          <div
            key={index}
            className="card"
            style={{ background: category.gradient }}
          >
            <div className="card-content">
              <h3 className="card-title">{category.title}</h3>
              <p className="card-description">{category.description}</p>
              <a href={category.link} className="card-link">
                Learn More
              </a>
            </div>
          </div>
        ))}
      </div>
    </div>
  );
};

export default Categories;
