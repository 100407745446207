import React from "react";
import "./Parcel.css";
import bgVideo from "../../Images/parcel.mp4";
import book from "../../Images/Parcel/book.png";
import collect from "../../Images/Parcel/collection.png";
import deliver from "../../Images/Parcel/delivery.png";

const Parcel = () => {
  return (
    <>
      <div className="parcel-container">
        <video className="background-video" autoPlay loop muted playsInline>
          <source src={bgVideo} type="video/mp4" />
          Your browser does not support the video tag.
        </video>
        <div className="parcel-content">
          <div className="left">
            <div className="use-case-box">
              <p className="use-case-title">Urgent Parcel</p>
            </div>
            <h1>
              Need a Same Day Courier{" "}
              <span className="highlight">right now</span>?
              <br />
              Blitzo Delivers Your Parcel in{" "}
              <span className="highlight">Minutes</span>
            </h1>
            <h2>
              Whether it's <span className="underline">forgotten keys</span>,{" "}
              <span className="underline">important documents</span>, or{" "}
              <span className="underline">last-minute gifts</span>, Blitzo
              ensures your items arrive safely and swiftly.
            </h2>
          </div>
        </div>
      </div>
      <HowItWorks />
    </>
  );
};

const HowItWorks = () => {
  return (
    <div className="how-it-works">
      <h2>How It Works - Instant Delivery</h2>
      <div className="steps">
        <div className="step">
          <div className="step-number">1</div>
          <img src={book} alt="Book Step" />
          <h2>Book a Pickup</h2>
          <p>
            Picture this: You've just realized you left your bag at a friend's
            place after a late-night study session. No worries! Open the{" "}
            <span className="underline">Blitzo app</span>, select a Category,"
            and enter your friend's address as the pickup location.
          </p>
        </div>
        <div className="step">
          <div className="step-number">2</div>
          <img src={collect} alt="Collect Step" />
          <h2>Quick Collection</h2>
          <p>
            Our courier arrives{" "}
            <span className="underline">within 10 minutes</span> at your
            friend's place. No more awkward calls or trying to figure out
            schedules. We handle it all seamlessly, so you can focus on your
            day.
          </p>
        </div>
        <div className="step">
          <div className="step-number">3</div>
          <img src={deliver} alt="Deliver Step" />
          <h2>Secure Delivery</h2>
          <p>
            Enjoy peace of mind as your bag is delivered safely to your door{" "}
            <span className="underline">in minutes</span>, just in time for your
            9 am's! With <span className="underline">real-time tracking</span>{" "}
            and secure handling, Blitzo ensures your valuables are in good
            hands.
          </p>
        </div>
      </div>
    </div>
  );
};

export default Parcel;
