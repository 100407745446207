import {useState} from 'react';
import WaitlistContext from './WaitlistContext';
import Modal from 'react-modal';
import UserWaitlist from '../components/UserWaitlist';

Modal.setAppElement('#root');

const WaitlistProvider = ({children}) => {
  const [modalOpen, setModalOpen] = useState(false);
  const [isRiderModal, setIsRiderModal] = useState(false);

  return (
    <WaitlistContext.Provider
      value={{modalOpen, setModalOpen, isRiderModal, setIsRiderModal}}>
      {/* Render children always and the modal on top when needed */}
      {children}
      <Modal
        isOpen={modalOpen}
        onRequestClose={() => setModalOpen(false)}
        style={{
          overlay: {
            backgroundColor: 'rgba(0, 0, 0, 0.5)',
            zIndex: '1000',
          },
          content: {
            top: '50%',
            left: '50%',
            right: 'auto',
            bottom: 'auto',
            marginRight: '-50%',
            transform: 'translate(-50%, -50%)',
            border: 'none',
            background: 'none',
            overflow: 'visible',
            borderRadius: '0',
            padding: '0',
            width: '100%',
          },
        }}>
        <UserWaitlist
          onFormSuccess={() => setModalOpen(false)}
          isRider={isRiderModal}
        />
      </Modal>
    </WaitlistContext.Provider>
  );
};

export default WaitlistProvider;
