import React from "react";
import "./Info.css";
import Tick from "../Icons/tick.png";

const Info = () => {
  return (
    <div className="info-container">
      <h2>Fastest Eco-Friendly Same Day Courier for London</h2>
      <div className="info-content">
        <div className="points">
          <div
            className="point"
            style={{
              animation: "float 5s ease-in-out infinite",
              animationDelay: "0s",
            }}
          >
            <h4>E-Bike Delivery Fleet</h4>
          </div>
          <div
            className="point"
            style={{
              animation: "float 6s ease-in-out infinite",
              animationDelay: "1s",
            }}
          >
            <h4>Public Transport Fleet</h4>
          </div>
          <div
            className="point"
            style={{
              animation: "float 7s ease-in-out infinite",
              animationDelay: "2s",
            }}
          >
            <h4>Advanced Route Optimization</h4>
          </div>
          <div
            className="point"
            style={{
              animation: "float 8s ease-in-out infinite",
              animationDelay: "3s",
            }}
          >
            <h4>Zero Packaging Policy</h4>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Info;
