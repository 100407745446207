import React from "react";
import "./Multidrop.css";
import multidropVideo from "../../Images/MultidropVideo.mp4";

const Multidrop = () => {
  return (
    <div className="multidrop-container">
      <div className="video-wrapper">
        <video className="background-video" autoPlay loop muted playsInline>
          <source src={multidropVideo} type="video/mp4" />
          Your browser does not support the video tag.
        </video>
        <div className="multidrop-content">
          <div className="left">
            {/* <div className="use-case-box">
              <p className="use-case-title">Multiple Dropoff Points</p>
            </div> */}
            <h1>
              <span className="highlight">Blitzo</span> can handle your{" "}
              <span className="highlight">multidrop</span> order in{" "}
              <span className="highlight">minutes</span>
            </h1>
            <h2>
              Need to send to{" "}
              <span className="underline">multiple locations</span> at the{" "}
              <span className="underline">same time</span>? Don't worry and use{" "}
              <span className="underline">Blitzo's</span>{" "}
              <span className="underline">quick and affordable</span>{" "}
              <span className="underline">multidrop feature</span>.
            </h2>
          </div>
        </div>
      </div>
      <div className="multidrop-info">
        <h2>What is Multidrop? An Essential Guide for UK Deliveries</h2>
        <p>
          Multidrop is a delivery system where a single pickup location sends
          packages to multiple drop-off points. This system involves multiple
          riders, each assigned to a specific delivery destination. This ensures
          faster and more efficient deliveries, as each rider can focus on
          completing a single drop-off without delays from additional stops.
          Multidrop ensures quicker and more efficient deliveries, particularly
          in busy urban areas like London. By using multiple riders for
          multidrop deliveries, packages are delivered faster, avoiding the
          delays often caused by traffic or multiple stops. Incorporating
          multidrop delivery methods enhances the speed and efficiency of parcel
          logistics.
        </p>
        <p>
          Blitzo uses this unique multidrop model to offer fast, same-day
          deliveries, with each rider focusing on a single drop-off, making the
          process efficient and time-saving. Multidrop services have become
          vital for PR firms, stylists and businesses looking to meet tight
          delivery schedules and maintain customer satisfaction, especially as
          the demand for rapid deliveries grows.
        </p>
        <h2>Why Choose Blitzo for Your Multidrop Deliveries in London?</h2>
        <p>
          Blitzo stands out in multidrop delivery particularly in London. One of
          the key advantages is its highly competitive pricing, charging as
          little as £3 per drop-off, making it one of the most affordable
          multidrop services available. This cost-efficiency is unmatched by
          other services. In terms of speed, Blitzo's multidrop model is far
          superior. Unlike other services that rely on a single rider for
          multiple deliveries, Blitzo assigns individual riders to each drop-off
          point, drastically reducing delivery time. With a pickup time of less
          than 15 minutes, even for orders with numerous drop-off points, Blitzo
          ensures that deliveries are completed faster and more efficiently.
          Another reason to choose Blitzo for your London multidrop needs is the
          convenience it offers through its app. Customers receive real-time
          updates on the status of each individual order within the multidrop
          request. Live tracking is available via a tracking link, which can be
          shared with others, ensuring transparency and complete visibility
          throughout the delivery process. This combination of speed,
          affordability, and convenience makes Blitzo the top choice for
          multidrop deliveries in London.
        </p>
        <h2>Top Features of Blitzo's Multidrop Route Planner</h2>
        <p>
          Blitzo's multidrop route planner plays a crucial role in streamlining
          deliveries, especially for businesses and customers handling multiple
          drop-offs. One of the standout features is that when placing a
          multidrop order, the customer receives an Estimated Time of
          Completion, ensuring clarity on when all the deliveries within the
          multidrop order will be completed. This estimate helps users manage
          expectations, particularly for time-sensitive deliveries.
        </p>
        <p>
          In addition, users can track the progress of each individual delivery
          within the multidrop order. Through the multidrop app feature,
          customers are regularly updated on the status of each delivery,
          ensuring they are informed every step of the way. Notifications
          include order processing, rider allocation, and successful drop-off
          confirmations, which adds a layer of transparency to the entire
          process.
        </p>
        <p>
          Perhaps the most valuable feature of the multidrop route planner is
          the live tracking functionality. Customers can track every single
          order within the multidrop delivery in real-time through a tracking
          link, which is available in the multidrop app. This link can also be
          shared with others, allowing customers or recipients to stay updated
          on delivery progress without needing to log into the app themselves.
        </p>
        <p>
          Blitzo's advanced multidrop route planner incorporates these features
          to ensure smooth and efficient deliveries across London. This system
          keeps customers fully informed, maximising convenience and
          satisfaction.
        </p>
        <h2>
          Becoming a Multidrop Delivery Driver: Key Skills and Requirements
        </h2>
        <p>
          To become a multidrop delivery driver with Blitzo, interested riders
          must first sign up through the Blitzo Rider App. During registration,
          the prospective multidrop driver selects their vehicle type and
          uploads the required documents as specified in the app. After document
          submission and verification, riders complete KYC (Know Your Customer)
          verification through Stripe. This ensures every multidrop driver is
          fully authenticated before starting deliveries.
        </p>
        <p>
          By handling just one delivery at a time, each rider can deliver with
          precision, which is especially important in busy cities like London.
          This specialised system ensures that multidrop orders are completed
          quickly, with a focus on reducing delivery times and increasing
          overall customer satisfaction.
        </p>
      </div>
    </div>
  );
};

export default Multidrop;
