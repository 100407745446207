import React, { useState } from "react";
import "./FAQ.css";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faChevronDown, faChevronUp } from "@fortawesome/free-solid-svg-icons";

const FAQ = () => {
  const [activeTab, setActiveTab] = useState("general");
  const [activeIndex, setActiveIndex] = useState(null);

  const questions = {
    general: [
      {
        q: "How big can my package be?",
        a: "Your package can be of any size, and at the point of placing the order you will be asked to specify the package size.",
      },
      {
        q: "Where do you deliver?",
        a: "We currently serve all zones of London, however we can promise instant pickup and drop off only within zones 1 & 2.",
      },
      {
        q: "What happens if my receiver is not there during delivery time?",
        a: "You'll incur a small fee for bounced deliveries. The package will be safely returned to us, however we cannot guarantee this and do not take responsibility of the package.",
      },
      {
        q: "How quickly can I expect delivery after placing an order?",
        a: "We prioritize swift service, and aspire to deliver under 30 minutes. Most packages are delivered within the same day, depending on the time of order placement and location.",
      },
      {
        q: "Is it possible to schedule a delivery for a specific time?",
        a: "Not quite yet. We are working on bringing this feature to you ASAP.",
      },
      {
        q: "Are there any items that Blitzo cannot deliver?",
        a: "These items are listed in our user terms and conditions, for example we cannot allow delivery of hazardous materials, illegal items, or perishable goods without proper packaging.",
      },
      {
        q: "What items are prohibited from delivery with Blitzo?",
        a: "These items are listed in our user terms and conditions. Generally, this includes but is not limited to dangerous goods, illegal substances, and overly bulky or heavy items.",
      },
      {
        q: "Can I change my delivery details after placing an order?",
        a: "No, modifications cannot be made once the order is placed. Please ensure all details are correct before confirming your order.",
      },
      {
        q: "What measures does Blitzo take for lost or damaged packages?",
        a: "We try to take every precaution to handle your deliveries with care. In the rare event of loss or damage, please contact customer service immediately for assistance.",
      },
    ],
    business: [
      {
        q: "How do I become a Blitzer?",
        a: "In order to become a blitzer you will have to download and sign up on our mobile app.",
      },
      {
        q: "What are the requirements to be a Blitzer?",
        a: "You need to have a reliable mode of transport, a smartphone, a delivery bag with the dimensions 35cm x 35cm x 40cm and pass a background check. In addition, you must be 18 years or older.",
      },
      {
        q: "How much can I earn as a Blitzer?",
        a: "Earnings depend on the number of deliveries and distance covered.",
      },
      {
        q: "How does the Blitzo app track and pay for my deliveries?",
        a: "The app tracks your delivery distance and time. Payments are calculated accordingly and disbursed weekly directly to your bank account.",
      },
      {
        q: "Can I work for Blitzo and other delivery services simultaneously?",
        a: "Yes, Blitzers are independent contractors and can work with other delivery platforms.",
      },
      {
        q: "Is there a specific schedule I need to follow?",
        a: "Blitzers enjoy flexible scheduling. Choose when and how much you want to work.",
      },
      {
        q: "Are there any incentives for Blitzers?",
        a: "We provide performance-based incentives and bonuses to our dedicated Blitzers, such as giving them priority for delivery requests.",
      },
      {
        q: "What types of vehicles can I use for Blitzo deliveries?",
        a: "You can use bicycles, motorcycles, or e-bikes for deliveries, provided they meet our safety standards, checks and local regulatory requirements.",
      },
      {
        q: "Can I deliver outside of London as a Blitzer",
        a: "Currently, our delivery network is focused within London.",
      },
      {
        q: "How does Blitzo handle insurance and liability for Blitzers?",
        a: "Blitzers are responsible for their own vehicle insurance.",
      },
      {
        q: "How are disputes or complaints from customers handled by Blitzo?",
        a: "We have a clear process for managing complaints, ensuring fairness and support for both customers and Blitzers. Disputes are investigated thoroughly by our support team.",
      },
    ],
  };

  // Function to toggle the active state of questions
  // const toggleActive = (index) => {
  //   setActiveQuestions((prevActiveQuestions) => ({
  //     ...prevActiveQuestions,
  //     [index]: !prevActiveQuestions[index],
  //   }));
  // };

  // const isActive = (index) => !!activeQuestions[index];

  const handleTabChange = (tab) => {
    setActiveTab(tab);
    setActiveIndex(null); // Reset activeIndex to close all questions
  };

  const toggleActive = (index) => {
    setActiveIndex(activeIndex === index ? null : index);
  };

  const isActive = (index) => activeIndex === index;

  return (
    <div className="faq-section">
      <h2>Frequently asked questions</h2>
      <p className="faq-text">
        Find quick answers to your queries about Blitzo's delivery services.
      </p>
      <div className="tabs">
        <button
          className={`tab ${activeTab === "general" ? "active" : ""}`}
          onClick={() => handleTabChange("general")}
        >
          User
        </button>
        <button
          className={`tab ${activeTab === "business" ? "active" : ""}`}
          onClick={() => handleTabChange("business")}
        >
          Rider
        </button>
      </div>
      <div className="questions-grid">
        {questions[activeTab].map((item, index) => (
          <div
            key={index}
            className={`question ${isActive(index) ? "active" : ""}`}
            onClick={() => toggleActive(index)}
          >
            <div className="question-content">
              <p className="question-title">{item.q}</p>
              {/* Conditionally render the chevron icon based on active state */}
              <FontAwesomeIcon
                icon={isActive(index) ? faChevronUp : faChevronDown}
                className="chevron-icon"
              />
            </div>
            <div className={`answer ${isActive(index) ? "show" : ""}`}>
              <p>{item.a}</p>
            </div>
          </div>
        ))}
      </div>
      <p className="faq-text">
        *[Got More Questions or have feedback? Contact Us]*
      </p>
    </div>
  );
};

export default FAQ;
