import React, { useEffect, useState, useCallback, useRef } from "react";
import { useParams } from "react-router-dom";
import {
  GoogleMap,
  LoadScript,
  Marker,
  DirectionsService,
  DirectionsRenderer,
} from "@react-google-maps/api";
import {
  getActiveOrder,
  subscribeToActiveOrder,
  fetchDistanceInMilesAndDurationInMinutes,
} from "../Firebase/order";
import "./Tracker.css";
import bike from "../Icons/bike.svg";
import box from "../Icons/box.svg";
import dropoff from "../Icons/dropoff.svg";
import bikeBox from "../Icons/bike_box.svg";

const defaultCenter = { lat: 51.509865, lng: -0.118092 };
const googleMapsAPIKey = "AIzaSyDc8ZE2eaxXND5WTbOeZ5BZc008_ykqv2M";

const Tracker = () => {
  const { orderId } = useParams();
  const [order, setOrder] = useState(null);
  const [mapCenter, setMapCenter] = useState(defaultCenter);
  const [riderToPickupDirections, setRiderToPickupDirections] = useState(null);
  const [pickupToDropoffDirections, setPickupToDropoffDirections] =
    useState(null);
  const [distDuration, setDistDuration] = useState({
    distance: 0,
    duration: 0,
  });
  const mapRef = useRef(null);

  const onLoad = useCallback((map) => {
    mapRef.current = map;
  }, []);

  const getStatusText = (status) => {
    switch (status) {
      case "pending":
        return "Finding a Blitzer";
      case "accepted":
        return "Heading to Pickup";
      case "pickedUp":
        return "En Route to Dropoff";
      case "delivered":
        return "Delivered";
      default:
        return "Loading...";
    }
  };

  const fetchDirections = useCallback(
    (origin, destination, setDirections) => {
      const directionsService = new window.google.maps.DirectionsService();
      directionsService.route(
        {
          origin: new window.google.maps.LatLng(
            origin.latitude,
            origin.longitude
          ),
          destination: new window.google.maps.LatLng(
            destination.latitude,
            destination.longitude
          ),
          travelMode:
            order?.fee?.sizeFee > 0
              ? window.google.maps.TravelMode.DRIVING
              : window.google.maps.TravelMode.BICYCLING,
        },
        (result, status) => {
          if (status === window.google.maps.DirectionsStatus.OK) {
            setDirections(result);
          }
        }
      );
    },
    [order]
  );

  const fetchDistanceAndDuration = useCallback(async (orderData) => {
    if (
      orderData.assignment &&
      orderData.pickupLocation &&
      orderData.dropoffLocation
    ) {
      if (orderData.status === "accepted") {
        const riderToPickup = await fetchDistanceInMilesAndDurationInMinutes(
          orderData.assignment.blitzerLocation,
          orderData.pickupLocation
        );
        const pickupToDropoff = await fetchDistanceInMilesAndDurationInMinutes(
          orderData.pickupLocation,
          orderData.dropoffLocation
        );
        setDistDuration({
          distance: riderToPickup.distance + pickupToDropoff.distance,
          duration: riderToPickup.duration + pickupToDropoff.duration + 3,
        });
      } else if (orderData.status === "pickedUp") {
        const pickupToDropoff = await fetchDistanceInMilesAndDurationInMinutes(
          orderData.assignment.blitzerLocation,
          orderData.dropoffLocation
        );
        setDistDuration({
          distance: pickupToDropoff.distance,
          duration: pickupToDropoff.duration,
        });
      }
    }
  }, []);

  const updateMapBounds = useCallback((orderData) => {
    if (orderData && mapRef.current) {
      const bounds = new window.google.maps.LatLngBounds();
      if (orderData.assignment) {
        bounds.extend(
          new window.google.maps.LatLng(
            orderData.assignment.blitzerLocation.latitude,
            orderData.assignment.blitzerLocation.longitude
          )
        );
      }
      if (orderData.pickupLocation) {
        bounds.extend(
          new window.google.maps.LatLng(
            orderData.pickupLocation.latitude,
            orderData.pickupLocation.longitude
          )
        );
      }
      if (orderData.dropoffLocation) {
        bounds.extend(
          new window.google.maps.LatLng(
            orderData.dropoffLocation.latitude,
            orderData.dropoffLocation.longitude
          )
        );
      }
      mapRef.current.fitBounds(bounds);
    }
  }, []);

  useEffect(() => {
    let unsubscribe;

    const fetchOrderAndSubscribe = async () => {
      const orderData = await getActiveOrder(orderId);
      setOrder(orderData);

      unsubscribe = subscribeToActiveOrder(orderId, (updatedOrderData) => {
        setOrder(updatedOrderData);
      });
    };

    fetchOrderAndSubscribe();

    return () => {
      if (unsubscribe) unsubscribe();
    };
  }, [orderId]);

  useEffect(() => {
    if (order) {
      setRiderToPickupDirections(null);
      setPickupToDropoffDirections(null);

      if (
        order.status === "accepted" &&
        order.assignment &&
        order.pickupLocation
      ) {
        fetchDirections(
          order.assignment.blitzerLocation,
          order.pickupLocation,
          setRiderToPickupDirections
        );
        fetchDirections(
          order.pickupLocation,
          order.dropoffLocation,
          setPickupToDropoffDirections
        );
      } else if (
        order.status === "pickedUp" &&
        order.assignment &&
        order.dropoffLocation
      ) {
        fetchDirections(
          order.assignment.blitzerLocation,
          order.dropoffLocation,
          setPickupToDropoffDirections
        );
      }

      fetchDistanceAndDuration(order);
      updateMapBounds(order);
    }
  }, [order, fetchDirections, fetchDistanceAndDuration, updateMapBounds]);

  const riderLocation = order?.assignment?.blitzerLocation;
  const pickupLocation = order?.pickupLocation;
  const dropoffLocation = order?.dropoffLocation;

  return (
    <div className="tracker-container">
      <LoadScript googleMapsApiKey={googleMapsAPIKey}>
        <GoogleMap
          options={{
            styles: mapStyle,
            mapTypeControlOptions: { mapTypeIds: [] },
            streetViewControl: false,
            fullscreenControl: false,
            zoomControl: false,
          }}
          onLoad={onLoad}
          mapContainerStyle={{
            height: "100vh",
            width: "100vw",
          }}
          center={mapCenter}
        >
          {riderLocation ? (
            order.status === "accepted" ? (
              <Marker
                position={{
                  lat: riderLocation.latitude,
                  lng: riderLocation.longitude,
                }}
                icon={{
                  url: bike,
                  scaledSize: new window.google.maps.Size(50, 50),
                }}
              />
            ) : (
              <Marker
                position={{
                  lat: riderLocation.latitude,
                  lng: riderLocation.longitude,
                }}
                icon={{
                  url: bikeBox,
                  scaledSize: new window.google.maps.Size(50, 50),
                }}
              />
            )
          ) : null}
          {pickupLocation && order.status !== "pickedUp" && (
            <Marker
              position={{
                lat: pickupLocation.latitude,
                lng: pickupLocation.longitude,
              }}
              icon={{
                url: box,
                scaledSize: new window.google.maps.Size(50, 50),
              }}
            />
          )}
          {dropoffLocation && (
            <Marker
              position={{
                lat: dropoffLocation.latitude,
                lng: dropoffLocation.longitude,
              }}
              icon={{
                url: dropoff,
                scaledSize: new window.google.maps.Size(50, 50),
              }}
            />
          )}
          {riderToPickupDirections && (
            <DirectionsRenderer
              options={{
                directions: riderToPickupDirections,
                suppressMarkers: true,
                polylineOptions: {
                  strokeColor: "#8428ed",
                  strokeOpacity: 1,
                  strokeWeight: 5,
                },
              }}
            />
          )}
          {pickupToDropoffDirections && (
            <DirectionsRenderer
              options={{
                directions: pickupToDropoffDirections,
                suppressMarkers: true,
                polylineOptions: {
                  strokeColor: "#e54c4c",
                  strokeOpacity: 1,
                  strokeWeight: 5,
                },
              }}
            />
          )}
        </GoogleMap>
      </LoadScript>
      <div className="info-overlay">
        <div className="info-card order-id">
          <h2>Order ID</h2>
          <p>{orderId}</p>
        </div>
        <div className="info-card distance">
          <h2>Distance</h2>
          <p>
            {distDuration.distance === 0
              ? "-"
              : `${distDuration.distance.toFixed(2)} miles`}
          </p>
        </div>
        <div className="info-card eta">
          <h2>ETA</h2>
          <p>
            {distDuration.duration === 0
              ? "-"
              : `${Math.floor(distDuration.duration)} minutes`}
          </p>
        </div>
        <div className="info-card status">
          <h2>Status</h2>
          <p>{order?.status ? getStatusText(order.status) : "Loading..."}</p>
        </div>
      </div>
    </div>
  );
};

export default Tracker;

export const mapStyle = [
  {
    featureType: "all",
    elementType: "geometry.fill",
    stylers: [
      {
        visibility: "on",
      },
    ],
  },
  {
    featureType: "administrative",
    elementType: "all",
    stylers: [
      {
        color: "#f2f2f2",
      },
    ],
  },
  {
    featureType: "administrative",
    elementType: "labels.text.fill",
    stylers: [
      {
        color: "#686868",
      },
      {
        visibility: "on",
      },
    ],
  },
  {
    featureType: "landscape",
    elementType: "all",
    stylers: [
      {
        color: "#f2f2f2",
      },
    ],
  },
  {
    featureType: "poi",
    elementType: "all",
    stylers: [
      {
        visibility: "off",
      },
    ],
  },
  {
    featureType: "poi.park",
    elementType: "all",
    stylers: [
      {
        visibility: "on",
      },
    ],
  },
  {
    featureType: "poi.park",
    elementType: "labels.icon",
    stylers: [
      {
        visibility: "off",
      },
    ],
  },
  {
    featureType: "road",
    elementType: "all",
    stylers: [
      {
        saturation: -100,
      },
      {
        lightness: 45,
      },
    ],
  },
  {
    featureType: "road.highway",
    elementType: "all",
    stylers: [
      {
        visibility: "simplified",
      },
    ],
  },
  {
    featureType: "road.highway",
    elementType: "geometry.fill",
    stylers: [
      {
        lightness: "-22",
      },
      {
        visibility: "on",
      },
      {
        color: "#b4b4b4",
      },
    ],
  },
  {
    featureType: "road.highway",
    elementType: "geometry.stroke",
    stylers: [
      {
        saturation: "-51",
      },
      {
        lightness: "11",
      },
    ],
  },
];
