import React, { useContext, useEffect } from "react";
import "./Home.css";
import WhyBlitzo from "../components/WhyBlitzo";
import LandingPage from "../components/LandingPage";
import ComingSoon from "../components/ComingSoon";
import Categories from "../components/Categories";
import Info from "../components/Info";
import ContentBlock from "../components/ContentBlock";
import UserForm from "../components/UserForm";
import WaitlistContext from "../Contexts/WaitlistContext";
import AOS from "aos";
import "aos/dist/aos.css";

function Home() {
  const { setIsRiderModal } = useContext(WaitlistContext);
  useEffect(() => {
    setIsRiderModal(false);
  }, []);

  useEffect(() => {
    setIsRiderModal(false);
    AOS.init({
      duration: 1200, // Animation duration (ms)
      easing: "ease-in-out-back", // Animation easing function
      once: true, // Whether animation should happen only once - while scrolling down
    });
  }, []);
  return (
    <div className="home-container">
      <LandingPage />
      <Categories />

      <div data-aos="fade-right" data-aos-duration="1200">
        <ContentBlock />
      </div>
      <div data-aos="fade-up" data-aos-duration="1200">
        <Info />
      </div>
      <div data-aos="fade-up" data-aos-duration="1200">
        <WhyBlitzo />
      </div>
      {/* <div data-aos="fade-up" data-aos-duration="1200">
        <UserForm />
      </div> */}
      {/* <ComingSoon /> */}
    </div>
  );
}

export default Home;
